import { ReactQueryDevtools } from "react-query/devtools";
import "./App.css";
// import Footer from "./components/headers/Footer";
// import MenuLists from "./components/headers/MenuLists";
// import MainRoutes from "./helpers/routes/MainRoutes";
import MainRoutes from "./Routes";
function App() {
  return (
    <>
      <MainRoutes />
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </>
  );
}

export default App;
