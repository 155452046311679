import React, { Suspense } from 'react';
import { lazy } from 'react';

import { Navigate, Route, Routes } from 'react-router';
const InnerContent = lazy(() => import('./utils/routes/InnerContent'));
const LoadingSuspense = lazy(() => import('./utils/loading/loadingSuspense/LoadingSuspense'));
const ProtectedRoutes = lazy(() => import('./utils/routes/ProtectedRoutes'));
const PublicRoutes = lazy(() => import('./utils/routes/PublicRoutes'));
// const NewDashboard = lazy(() => import('./pages/protected/NewDashboard'));
const SupportDashboard = lazy(() => import('./pages/protected/SupportDashboard'));
const LoginPage = lazy(() => import('./pages/public/LoginPage'));
const MainRoutes = () => {
  return (
    <Suspense fallback={<LoadingSuspense />}>
      <Routes>
        <Route path="/" element={<ProtectedRoutes />}>
          <Route path="/" element={<InnerContent />}>
            <Route path="/" element={<Navigate replace to="dashboard" />} />
            <Route path="dashboard" element={<SupportDashboard />} />
            {/* <Route path="support" element={<SupportDashboard />} /> */}
          </Route>
        </Route>
        <Route path="/" element={<PublicRoutes />}>
          <Route path="/" element={<InnerContent />}>
            <Route path="/login" element={<LoginPage />} />
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
};

export default MainRoutes;
